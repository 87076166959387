export const HOME_PAGE = 'https://stg.247tech.xyz'
export const TRACKING_LINK = 'https://tracking-stg.247tech.xyz'
export const GW_COD_API = 'https://cod-api-stg.247tech.xyz'
export const PMS_ENDPOINT = 'https://newcod-api-stg.247tech.xyz/api'
export const V4_ENDPOINT = 'https://v4-api-stg.247tech.xyz/api'
export const OTP_ENDPOINT = 'https://otp-stg.247tech.xyz/api'
export const INVOICE_ENDPOINT = 'https://hoadon-stg.247tech.xyz/service/api'
export const NOTIFICATION_ENDPOINT = 'https://capi-stg.247tech.xyz/api'
export const IMAGE_HOST = 'https://admin-v3-stg.247tech.xyz/files/'
export const SIGN_IN_REDIRECT = '/dang-nhap'
export const SIGN_UP_REDIRECT = '/dang-ky'
export const CRM_API = 'https://crmapi-v3-stg.247tech.xyz/api'
// API Endpoints
//export const HOME_PAGE = 'https://247tech.xyz'
//export const TRACKING_LINK = 'https://tracking-dev.247post.vn'
//export const GW_COD_API = 'https://api.247post.vn'
//export const PMS_ENDPOINT = 'https://api-kh.247post.vn/api'
//export const V4_ENDPOINT = 'https://v4-api.247tech.xyz/api'
//export const OTP_ENDPOINT = 'https://otp-dev.247tech.xyz/api'
//export const INVOICE_ENDPOINT = 'https://hoadon-dev.247tech.xyz/service/api'
//export const NOTIFICATION_ENDPOINT = 'http://222.252.27.138:40033/api'
//export const IMAGE_HOST = 'https://admin-v3-dev.247tech.xyz/files/'
//export const SIGN_IN_REDIRECT = '/dang-nhap'
//export const SIGN_UP_REDIRECT = '/dang-ky'
//export const CRM_API = 'https://crmapi-v3-dev.247tech.xyz/api'

// !!!deprecated => add new api-route to specific API-endpoints below
export const APIConstants = {
	API_INSERT_PRICE_CONTACT: '/Contact/InsertForContact',
	API_GET_HOME_CARDS: '/Card/GetCards',
	API_GET_MENU_ITEMS: '/MenuItem/GetMenuItems',
	API_GET_GALLERY_BY_SECTION: '/Gallery/GetGalleryBySection',
	API_GET_DISTRICT_BY_PROVINCE: '/MasterData/Getdistricts',
	API_GET_WARD_BY_DISTRICT: '/MasterData/GetWards',
	API_GET_PROVINCE: '/MasterData/Getprovinces',
	API_GET_SYSTEM_PROVINCE: '/Province/GetProvinces',
	API_GET_DISTRICTS: '/MasterData/Getdistricts?provincecode=',
	API_PUBLIC_SERVICE: GW_COD_API + '/api/masterdata/PublicServices',

	API_GET_PRICE: GW_COD_API + '/api/order/referprice',
	API_GET_WARD: '/MasterData/GetWards?districtCode=',
	API_GET_WIDGET: '/widget/GetWidgetValue',
	API_GET_RESOURCE: '/widget/GetWidgetByKey',
	API_GET_FEATURE_POST: '/Post/GetPostsForHome',
	API_GET_LIST_JOB: '/Job/ListJob',
	API_GET_JOB_TYPE: '/Job/GetJobTypeItem',
	API_GET_LIST_POST_OFFICE: '/MasterData/GetPublicPostOffices',
	API_GET_POST: '/Post/GetPosts',
	API_GET_LIST_POST: '/Post/GetListPost',
	API_GET_ALL_POST: '/Post/GetAllPosts',
	API_GET_POST_BY_SLUG: '/Post/GetPostDetailBySlug/',
	API_GET_POST_BY_ID: '/Post/GetPost/',
	API_GET_POST_BY_ID_AND_SLUG: '/Post/GetPostBySlugAndId/',
	API_GET_HOME_POSTS: '/Post/GetPostsForHome',
	API_GET_LIST_CATEGOTY_POST: '/PostCategory/GetPostCategories',
	API_GET_POST_FEATURE: '/Post/GetPostFeature',
	API_TRACKING: HOME_PAGE + '/api/TraCuu/',
	API_TRACKINGMULTI: TRACKING_LINK + '/api/TrackingMulti/',
	API_GET_PROVINCE_BO: '/Province/GetProvinceItem',
	API_GET_DISTRICT_BO: '/District/GetDistrictItem',
	API_GET_LIST_JOB_TYPE: '/Job/GetListJobTypeItem',
	API_SUBSCRIBE: '/Subscribe/Insert',
	API_GET_POST_DETAIL: '/Post/GetPostDetail',
	API_GET_JOB_DETAIL: '/Job/GetJob',
	API_CANDIDATE_INSERT: '/Candidate/Insert',
	API_PROMOTION_GET_LIST: '/Gallery/GetPromotionPager',
	API_TRACKING_TEST: TRACKING_LINK + '/api/Tracking/',
	API_FORM_DATA_INSERT: '/FormData/Insert',
	API_UPLOAD_FILE: `Upload/user/PostUserImage`,
	API_GET_PARTNER_COMMENT: '/PartnerComment/GetListPartnerCommentActive',
	API_GET_FAQ: '/Faq/GetListFaqActive',
	API_SEARCH: '/Util/Search',
	API_CLIENT_LOGIN: '/Client/ClientLogin',
	API_CLIENT_REGISTER: '/Client/RegisterFromWeb',
	API_INSERT_CONTACT: '/Contact/Insert',
	API_INSERT_CONTACT_PUSH_TO_BIZ: '/Contact/InsertForContactForOnPageSEO',
	API_ORDER_GET_VERSION:'/Order/GetCurVersions',
	API_IMPORT_TEMPLATE_TK:'/Order/DownloadImportTemplateTaiKien',
	API_IMPORT_GET_COUNT_HISTORY: '/Order/GetNumberOfFileImportForStatus',
	API_IMPORT_GET_LIST_HISTORY: '/Order/GetImportOrderHistory',
	API_IMPORT_GET_HISTORY_DETAIL: '/Order/GetImportHistoryDetail',
	API_IMPORT_GET_INVOICE_DETAIL: '/Order/GetItemsByImportDetailID',
	API_IMPORT_ORDERS: '/Order/ImportOrders',
	API_IMPORT_TEMPLATE: '/Order/DownloadImportTemplate',
	API_IMPORT_REUPLOAD_WITHOUT_ERROR: '/Order/ReUploadImportWithoutErrorOrders',
	API_IMPORT_REUPLOAD: '/Order/ReUploadImportOrders',
	API_IMPORT_GET_ERROR_BY_ID: '/Order/GetOrderErrorByImportID',
	API_EXPORT_ERROR_BY_ID: '/Order/ExportOrderErrorsByImportID',
	API_GET_CLIENT_HUBS: 'Client/GetClientHubs',
	API_GET_PROFILE_CLIENT_HUBS: 'Client/ProfileGetClientHubs',
	API_GET_CLIENT_HUBS_COMBOBOX: 'Client/GetClientHubsCombobox',
	API_GET_CLIENT_HUBS_FILTER_COMBOBOX: 'Client/GetClientHubsFilterCombobox',
	API_GET_OTP: '/OTP/GetOTPV2',
	API_CHECK_TAXCODE: '/Client/RegisterCustomerCheckTaxCode',
	API_SERVICE_REQUIRE_MANAGE: '/Customer/WebAppGetItemList',
	API_SERVICE_REQUIRE_STATUS: '/Customer/WebAppGetTotalItemByStatus',
	API_ACTIVE_ACCOUNT: '/Client/CustomerActiveWebAppAccountRequest',

	BASE_URL_PAGE: HOME_PAGE,
	BASE_URL_POST: HOME_PAGE + '/tin-tuc',
	BASE_URL_JOB: HOME_PAGE + '/tuyen-dung',
	BASE_URL_EXTRA: HOME_PAGE + '/dich-vu-gtgt',
	BASE_URL_FAQ: HOME_PAGE + '/hoi-dap',

	//CRM url
	API_CRM_CREATE_LEAD: CRM_API + '/WebKH/Webhook',
}

export const PMS_APIs = {
	API_GET_EXTRA_SERVICE_BY_SERVICE_TYPE: '/MasterData/GetServiceByServiceType',
	API_GET_HOME_EXTRA_SERVICE: '/MasterData/PublicExtraServices',
	API_GET_ALL_PRIVATE_EXTRA_SERVICE: '/MasterData/ServiceTypes',
	API_GET_LIST_EXTRA_SERVICE_INSERT_UPDATE: '/MasterData/GetServiceTypesForInsertUpdateHub',
	API_GET_ALL_SERVICE_FOR_CLIENT: '/MasterData/GetAllServiceForClient',
	API_GET_PROVINCES: '/MasterData/ProvincesByCountryID',
	API_GET_ALL_DISTRICTS: '/MasterData/Districts',
	API_GET_ALL_WARDS: '/MasterData/Wards',
	API_GET_DISTRICTS_BY_PROVINCEID: '/MasterData/DistrictsByProvinceID',
	API_GET_WARDS_BY_DISTRICTID: '/MasterData/WardsByDistrictID',
	API_CALC_PRICE: '/Price/ReferPrice',
	API_CALC_PRICE_INTERNATIONAL: '/Price/ReferPriceInternational',
	API_GET_CLIENT_ROOT_PROFILE: '/Client/GetEnterpriseProfile',
	API_UPDATE_CLIENT_HUB: '/Client/UpdateClientHub',
	API_GET_CPN_ORDER: '/Order/SearchCPNOrders',
	API_CREATE_CLIENT_HUB: '/Client/CreateClientHub',
	API_CHANGE_TRACKING_API_KEY: '/Client/ChangeTrackingApiKey',
	API_GET_CLIENT_PROFILE: '/Client/GetClientProfile',
	API_GETALL_CUSTOMER_COMPLAIN: '/Customer/WebAppCustomerGetListTicketKNTVHT',
	API_GETALL_CATEGORY_COMPLAIN: '/Customer/GetTicketInfoTypeCbb',
	API_CREATEITEM: '/Customer/WebAppCreateItem',
	API_UPDATE_MAIN_HUB: '/ClientHub/SetMainHub',
	API_CLIENT_CHANGE_PASSWORD: '/Client/ChangePassword',
	API_CLIENT_GET_CONTACTS: '/Client/ProfileGetContacts',
	API_CLIENT_UPDATE_CONTACT: '/Client/ProfileUpdateContacts',
	API_CLIENT_GET_NOTI_SETTING: '/Client/ClientHubGetNotiSettings',
	API_CLIENT_UPDATE_NOTI_SETTING: '/Client/ClientHubUpdateNotiSettings',
	API_GET_COD_ORDER: '/Order/SearchCODOrders',
	API_GET_COMPLAIN_BY_STATUS: '/Customer/WebAppCustomerGetTotalTicketsByStatus',
	API_GET_HPGET_ORDERS_TOTAL_OVER_STATUS_CPN: '/Order/HPGetOrdersTotalOverStatusCPN',
	API_GET_HPGET_ORDERS_TOTAL_OVER_STATUS_COD: '/Order/WebHPGetOrdersTotalOverStatusCOD',
	API_DOWNLOAD_IMAGES: '/Order/DownloadImages',
	API_GET_SMS_LOG: '/Client/GetSMSLogs',
	API_CREATE_COMPLAIN: '/Customer/WebAppCustomerCreateTicketKNTVHT',
	API_UPDATE_TRANSACTION_INFO: '/Client/UpdateTransactionInvoiceInfo',
	API_UPDATE_REPRESENTATIVE: '/Client/UpdateRepresentatives',
	API_UPDATE_REPRESENTATIVE_V2: '/Customer/WebAppV2UpdateRepresentatives',
	API_CHECK_UPDATE_PAYMENT_INFO: '/Client/CheckUpdatePaymentOrderInfo',
	API_UPDATE_PAYMENT_INFO: '/Client/UpdatePaymentOrderInfo',
	API_GET_BANK_PAYMENT_INFO: '/MasterData/GetBankPaymentInfo',
	API_GET_COUNTRIES: '/MasterData/Countries',
	API_FORGOT_PASSWORD_MAIL_OTP: '/Client/ForgotPassword',
	API_VERIFY_OTP_FORGOT_PASSWORD: '/Client/VerifyOTPForgotPassword',
	API_RESET_PASSWORD: '/Client/ResetPassword',
	API_CHECK_ADDRESS: '/MasterData/AddressSearch',
	API_CREATE_ORDER: '/Order/CreateOrder',
	API_DEBT_REPORT: '/Customer/GetDebtRptsDashBoard',
	API_GET_COMPLAIN: '/Customer/WebAppCustomerGetTicketInfo',
	API_CHART_INFO: '/Customer/WebHPGetFeeTotalOverView',
	API_OMGET_ORDERS_TOTAL_OVER_STATUS: '/Order/OMGetOrdersTotalOverStatus',
	API_PRINT_PACKAGES: '/Order/OrdersPrintPackages',
	API_CALCULATE_FEE: '/Price/CalculateServiceFee',
	GET_CPN_TOTAL_LIST: '/Customer/TotalDebtRptByStatus',
	GET_COD_TOTAL_LIST: '/Order/CODFeeGetTotalByStatus',
	GET_CPN_LIST: '/Customer/GetDebtRptsV2',
	DOWNLOAD_CPN: '/Customer/DebtRptsDownloadFileV2',
	API_GET_ORDERS_INFO: '/Order/GetOrderInfos',
	API_PRINT_ORDER_NEW: '/Order/PrintOrders',
	GET_COD_LIST: '/Order/CODFeeGetDetail',
	GET_TRANSFER_SESSIONS: '/Order/CODFeeGetTransferSession',
	GET_ORDER_INFO: '/Order/GetOrderInfo',
	API_UPDATE_ORDER: '/Order/UpdateOrder',
	GET_TRANFER_LIST: '/Order/CODFeeGetTransferSession',
	API_GET_LATEST_NOTIFS: '/Client/GetLatestNotifications',
	API_GET_RECEIVER_REMIND: '/Client/GetReminder',
	API_GET_NOTIFICATIONS: '/Client/GetNotifications',
	API_GET_IMAGE_URLS: 'Order/GetImageUrls',
	API_GET_QLDH_IMAGE_BY_CONDITIONS: 'Order/QLDHDownloadImagesByConditions',
	API_GET_IMAGE_BY_CONDITIONS: 'Order/DownloadImagesByConditions',
	API_REMOVE_ORDER: '/Order/CancelOrder ',
	API_SUBMIT_ORDER: 'Order/UpdateDraftToUse',
	API_PRINT_BARCODE: '/Order/GetBarcodeOrders',
	API_UPDATE_DRAFT_TO_USE: '/Order/UpdateDraftToUse',
	API_EXPORT_ORDER: 'Order/ExportOrder',
	API_EXPORT_ORDER_BYID: 'Order/ExportOrdersByIds',
	API_REDELIVERY: 'Order/Redelivery',
	API_RETURN_ORDER: 'Order/ReturnOrder',
	API_FORGOT_PASSWORD_BY_INFO: 'Client/ForgotPassVerifyByInfo',
	API_GET_SERVICE_BY_CUSTOMER: 'MasterData/GetServiceTypeByCustomerID',
	API_GET_SERVICE_BY_CLIENTHUBID: 'MasterData/GetServiceTypeByClientHubID',
	API_SET_AVATAR: 'Client/SetAvatar',
	API_REMOVE_AVATAR: 'Client/RemoveAvatar',
	API_GET_WISHDATE: 'Customer/GetPickWishDateItems',
	API_GET_CHANGE_HISTORY: '/Order/GetCustomerChangeInfoHistories',
	API_DESTROY_ORDER: 'Order/DestroyOrder',
	API_CHECK_USERNAME: 'Client/ForgotPassCheckUser',
	API_FORWARD_ORDER: '/Order/ForwardOrder',
	API_CREATE_REPRESENTATIVE: '/Client/CreateRepresentative',
	API_REMOVE_HUB_WAIT_ACCEPT: '/Client/RemoveClientHub',
	API_GET_CAL_WEIGHT: '/Price/GetCalWeight',
	API_GET_WEIGHTS: '/Price/GetWeights',
	API_CHECK_EXPORT_ORDER: 'Order/RouteExportOrders',
	API_CHECK_EXPORT_IMAGE: 'Order/RouteDownloadImagesByConditions',
	API_CHECK_EXPORT_QLDH_IMAGE: 'Order/RouteQLDHGetOrderCodesForDownloadImages',
	API_EXPORT_BY_SENDMAIL: 'Order/ExportByOrdersbyConditionsSendMails',
	API_CREATE_OR_UPDATE_ORDER_GET_CLIENT_HUB_COMBOBOX_DETAIL:
		'Client/CreateUpdateOrderGetClientHubComboboxDetail',
	API_GET_REMINDER_PHONES: 'Client/GetReminderPhones',
	API_CHECK_CUSTOMER_FOR_ACTION: '/Client/CheckCustomerForAction',
	API_GET_REASON_CANCEL: 'MasterData/GetReasonsCancelItemWebApp',
	API_CANCEL_REQUEST: 'Customer/CancelItems',
	API_GET_WEIGHT: '/Price/GetWeight',
	API_GET_DISPLAY_SETTING: 'Client/GetDisplaySettingSearchOrder',
	API_SAVE_DISPLAY_SETTING: 'Client/SaveDisplaySettingSearchOrder',
	API_SEARCH_CPN_ORDERS_WITH_DISPLAY_SETTING: 'Order/SearchCPNOrdersWithDisplaySettings',
	API_SEARCH_COD_ORDERS_WITH_DISPLAY_SETTING: 'Order/SearchCODOrdersWithDisplaySettings',
	API_GET_ESTIMATE_DELIVERY_DATE:'masterdata/GetEstimateDeliveryDate',

}

export const V4_APIs = {
	API_GET_BANNER: '/Gallery/GetGalleryBannerByURL/',
	API_CHANGE_PASSWORD: '/Account/ChangePassword',
	GET_EXTRA_SERVICES: '/ExtraService/GetExtraServices',
	GET_PIN_POST: '/Post/GetPinPost',
	GET_PACK_SERVICES: '/Pack/GetAllPacks',
	SEARCH_POSTS: '/Post/GetAutoCompleteSearch',
	GET_ALL_PAGES: '/Page/GetAllActivePage',
	GET_PAGE_BY_SLUG: '/Page/GetPageDetail/',
	GET_STATISTICS: '/StatisticNumber/GetStatisticNumber',
}
export const CRM_APIs = {
	WEB_HOOK_REGISTER_ACCOUNT: '/WebKH/Webhook',
	WEB_HOOK_CHECK_DUPLICATE: '/WebKH/CheckIsDuplicateFromWebKH',
}

export const INVOICE_APIS = {
	SEARCH: '/Invoice/SearchInvoiceFromWebKH/',
	DOWNLOAD: '/Invoice/Download/',
	VIEW: '/Invoice/ViewXMLFromWebKH/',
	DOWNLOAD_OPTION: '/Invoice/GetDocumentDebitFromWebKH/',
}

export const NOTIF_APIS = {
	SUBCRIBE_NOTIF: '/Notification/WebSubscribe',
}
export const FIREBASE = {
	LEAD_INSERT_ERROR: 'LeadInsertError',
}

export default APIConstants
